import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from './pages/Login'
import UseMouseOrKeyboard from './modules/UseMouseOrKeyboard'
import DesignSystem from './DesignSystem/DesignSystem'
import { register } from 'swiper/element/bundle'
import logs from './utils/logs'
import ripple from './modules/ripple'
import formErros from './modules/formErros'
import animaAoScroll from './animation/animaAoScroll'
import animateSetup from './animation/animateSetup'
import menu from './drivenation/menu.js'
// import {setAnimationBase, toAnimationBase} from './animation/animationBase';
// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
// animaAoScroll()

// Pages
PageHome()
PageLogin()?.init()

// utils logs
logs()
// menu()

const swiperElsFreeMode = document.querySelectorAll('.swiper-container-free-mode')

const params = {
	// array with CSS styles
	injectStyles: [
		`
        .swiper-free-mode > .swiper-wrapper {
            transition-timing-function: linear !important;
        
        }
    `,
	],

	// array with CSS urls
}

swiperElsFreeMode.forEach((el) => {
	Object.assign(el, params)
	el.initialize()
})
