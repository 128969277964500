import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return


	const swiperBanner = new Swiper(".swiperBanner", {
		slidesPerView: "auto",
		// spaceBetween: "16",
		/*autoplay:{
			delay:"1000",
			disableOnInteraction:"false",
		},*/
		loop: "true",
		pagination: {
			el: ".swiper-pagination",
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	});

}

